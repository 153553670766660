<template>
    <div id="page-branch-list">
        <div class="vx-card p-6">
            <div class="flex flex-wrap items-center mt-5">
                <div class="flex-grow">
                    <vs-button
                        @click="addDailyWork"
                        color="primary"
                        type="border"
                        icon-pack="feather"
                        icon="icon-settings">
                        Thêm hạng mục cần kiểm tra
                    </vs-button>

                </div>

                <!-- ITEMS PER PAGE -->
                <Pagination
                    :itemList="dailyWorkDetails"
                    :currentPage="currentPage"
                    :paginationPageSize="paginationPageSize"
                    :gridApi="gridApi"
                />

                <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4"
                          v-model="searchQuery"
                          @input="updateSearchQuery" placeholder="Tìm kiếm..."/>
            </div>
            <!-- AgGrid Table -->
            <ag-grid-vue style="height: 55vh"
                         ref="agGridTable"
                         :components="components"
                         :gridOptions="gridOptions"
                         class="ag-theme-material w-100 my-4 ag-grid-table"
                         :columnDefs="columnDefs"
                         :defaultColDef="defaultColDef"
                         :rowData="dailyWorkDetails"
                         :animateRows="true"
                         :pagination="true"
                         :paginationPageSize="paginationPageSize"
                         :onGridReady="onGridReady"
                         :suppressPaginationPanel="true">
            </ag-grid-vue>

            <vs-pagination
                :total="totalPages"
                :max="maxPageNumbers"
                v-model="currentPage"/>
        </div>
    </div>
</template>

<script>
import vSelect from 'vue-select'
import {AgGridVue} from "ag-grid-vue"
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import Pagination from "../../ui-elements/Pagination.vue"
import CellRendererDetailActions from "./cell-renderer/CellRendererDetailActions.vue"

export default {
    components: {
        AgGridVue,
        vSelect,
        // Cell Renderer
        CellRendererDetailActions,
        Pagination
    },
    data() {
        return {
            searchQuery: "",
            maxPageNumbers: 7,
            // AgGrid
            gridApi: null,
            gridOptions: {},
            defaultColDef: {},
            columnDefs: [
                {
                    headerName: 'ID',
                    field: 'id',
                    width: 50
                },
                {
                    headerName: 'Các hạng mục cần kiểm tra',
                    field: 'name',
                    width: 250
                },
                {
                    headerName: 'Phương pháp',
                    field: 'methodCheck',
                    width: 150,
                },
                {
                    headerName: 'Yêu cầu công việc',
                    field: 'completionCriteria',
                    width: 250,
                },
                {
                    headerName: 'Actions',
                    field: 'actions',
                    width: 100,
                    cellRendererFramework: 'CellRendererDetailActions',
                },
            ],
            // Cell Renderer Components
            components: {
                CellRendererDetailActions,
            },
            dailyWorkDetails: []
        }
    },
    created() {
        this.initScreen()
    },
    watch: {
        '$store.state.windowWidth'(val) {
            if (val <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (val > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        '$store.state.reduceButton'() {
            setTimeout(() => {
                this.gridApi.sizeColumnsToFit();
            }, 650);
        },
        '$store.state.isUpdateWorkDetail'(val) {
            if (val) {
                this.initScreen();
                this.$store.commit('UPDATE_DAILY_WORK_DETAIL', false);
            }
        }
    },
    computed: {
        paginationPageSize() {
            if (this.gridApi) return this.gridApi.paginationGetPageSize();
            else return 20
        },
        totalPages() {
            if (this.gridApi) return this.gridApi.paginationGetTotalPages();
            else return 0
        },
        currentPage: {
            get() {
                if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
                else return 1
            },
            set(val) {
                this.gridApi.paginationGoToPage(val - 1)
            }
        }
    },
    methods: {
        updateSearchQuery(val) {
            this.gridApi.setQuickFilter(val)
        },
        addDailyWork() {
            this.$router.push(`/user/add-daily-work-detail`);
        },
        onGridReady() {
            if (this.$store.state.windowWidth <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (this.$store.state.windowWidth > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        initScreen() {
            this.$vs.loading();
            this.$crm.get('/work-detailed/find-all').then((response) => {
                this.dailyWorkDetails = response.data;
                this.$vs.loading.close();
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    title: 'Error',
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            }).finally(() => {
                this.gridApi.sizeColumnsToFit();
            });
        }
    },
    mounted() {
        this.gridApi = this.gridOptions.api;
    },
}
</script>

<style scoped>

</style>
